import {useEffect, useMemo, useState} from 'react';
import {WamAPIProvider as WamAPIProviderProps} from "@/modules/core/wamAPI/types/WamAPI";
import {wamAPIContext as WamApiContext} from '@/modules/core/wamAPI/contexts/wamAPIContext';
// import {userSessionContext as UserSessionContext} from '@/modules/core/userSession/contexts/userSessionContext';
import {useRouter} from 'next/navigation'
import axios from 'axios';
import {useCookies} from 'react-cookie';
import {createLogger} from "@/modules/core/logging/logger";
import {normaliseUser} from '@/modules/core/wamAPI/utils/normalise';
import {getGeocode, getLatLng} from "use-places-autocomplete";
import filter from "lodash.filter";
import groupBy from "lodash.groupby";
import includes from "lodash.includes";

const fileLabel = 'modules/core/userSession/providers/userSessionProvider.tsx';
const logger = createLogger({
  fileLabel,
});

export function WamAPIProvider({
  wamUsers,
  children
}: WamAPIProviderProps) {
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.info('WamAPIProvider wamUsers', wamUsers); // eslint-disable-line no-console
  }
  const [cookie, setCookie, removeCookie] = useCookies([
    'accessToken',
  ]);
  const wamapiConfig = {
    headers: {
      "Content-type": "application/json",
      "Authorization": `bearer ${cookie?.accessToken}`
    },
    withCredentials: true
  }

  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.info('WamAPIProvider cookie', cookie); // eslint-disable-line no-console
  }
  const router = useRouter()
  // NOTE State Persist Functions
  const initialProfileData = useMemo((): any => {
    let profileDataString: any = null
    if (typeof window !== 'undefined') {
      profileDataString = localStorage.getItem('profileData')
    }

    if (profileDataString) {
      console.log('dafuq', JSON.parse(profileDataString))
      return JSON.parse(profileDataString)
    } else {
      // return {
      //   isPlaying: false,
      //   currentAudio: null,
      // }
      return false
    }
  }, [])
  const initialProfileFriendsData = useMemo((): any => {
    let profileFriendsDataString: any = null
    if (typeof window !== 'undefined') {
      profileFriendsDataString = localStorage.getItem('profileFriendsData')
    }

    if (profileFriendsDataString) {
      console.log('MessagesInboxList dafuq 2', JSON.parse(profileFriendsDataString))
      return JSON.parse(profileFriendsDataString)
    } else {
      return false
    }
  }, [])
  const initialProfileInboxData = useMemo((): any => {
    let profileInboxDataString: any = null
    if (typeof window !== 'undefined') {
      profileInboxDataString = localStorage.getItem('profileInboxData')
    }

    if (profileInboxDataString) {
      console.log('MessagesInboxList profileInboxDataString', JSON.parse(profileInboxDataString))
      return JSON.parse(profileInboxDataString)
    } else {
      return false
    }
  }, [])
  const initialProfileOutboxData = useMemo((): any => {
    let profileOutboxDataString: any = null
    if (typeof window !== 'undefined') {
      profileOutboxDataString = localStorage.getItem('profileOutboxData')
    }

    if (profileOutboxDataString) {
      console.log('MessagesInboxList profileOutboxDataString', JSON.parse(profileOutboxDataString))
      return JSON.parse(profileOutboxDataString)
    } else {
      return false
    }
  }, [])
  const initialProfileMessagesData = useMemo((): any => {
    let profileMessagesDataString: any = null
    if (typeof window !== 'undefined') {
      profileMessagesDataString = localStorage.getItem('profileMessagesData')
    }

    if (profileMessagesDataString) {
      console.log('MessagesInboxList profileMessagesDataString', JSON.parse(profileMessagesDataString))
      return JSON.parse(profileMessagesDataString)
    } else {
      return false
    }
  }, [])
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.debug('WamAPIProvider initialProfileData', initialProfileData); // eslint-disable-line no-console
    logger.debug('WamAPIProvider initialProfileFriendsData', initialProfileFriendsData); // eslint-disable-line no-console
  }
  // NOTE State
  const [hydrationLoading, setHydrationLoading] = useState<Boolean>(true)
  const [unfilteredWamUsers, setUnfilteredWamUsers] = useState(wamUsers);
  const [filteredWamUsers, setFilteredWamUsers] = useState(false);
  const [liveWamUsers, setLiveWamUsers] = useState(false);
  const [usersDataFilteredByLocationAndDistance, setUsersDataFilteredByLocationAndDistance] = useState(wamUsers);
  const [profileData, setProfileData] = useState(initialProfileData);
  const [profileFriendsData, setProfileFriendsData] = useState<any>(initialProfileFriendsData);
  const [activeFriendData, setActiveFriendData] = useState<any>(initialProfileFriendsData && initialProfileFriendsData.length > 0 && initialProfileFriendsData[0] || false)
  // const [activeFriendData, setActiveFriendData] = useState<any>(false)
  const [userInbox, setUserInbox] = useState<any>(initialProfileOutboxData);
  const [userOutbox, setUserOutbox] = useState<any>(initialProfileInboxData);
  const [allUserMessages, setAllUserMessages] = useState<any>(initialProfileMessagesData);
  const [directMessageData, setDirectMessageData] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [primaryImage, setPrimaryImage] = useState(false)
  const [registeringUserPrimaryImage, setRegisteringUserPrimaryImage] = useState()
  // State of Users
  const [websocket, setWebsocket] = useState<any>();
  // NOTE Utils
  const adminEmailUserSignup = async (sendData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('adminEmailUserSignup: formData', sendData); // eslint-disable-line no-console
    }
    try {
      let {data} = await axios.post(
        '/api/logging/signup',
        sendData
      );
      const adminResponse = await axios.post(
        '/api/admin/signup',
        sendData
      )
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('handleEmailsUserRegistration - err', err); // eslint-disable-line no-console
      }
    }
  }
  const adminEmailUserRegistration = async (sendData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('adminEmailUserRegistration: sendData', sendData); // eslint-disable-line no-console
    }
    try {
      const {data} = await axios.post(
        '/api/logging/registration',
        sendData
      );
      const adminResponse = await axios.post(
        '/api/admin/registration',
        sendData
      )
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('adminEmailUserRegistration - err', err); // eslint-disable-line no-console
      }
    }
  }
  const getDistanceFromLatLonInKm = (lat1,
    lon1,
    lat2,
    lon2) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('getDistanceFromLatLonInKm lat1, lat2, lon1, lon2', lat1, lat2, lon1, lon2); // eslint-disable-line no-console
    }
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
  }
  const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const setLocalStorageItem = async (
    key,
    value
  ) => {
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.setItem(key, value)
    }
  }
  const removeLocalStorageItem = async (
    key
  ) => {
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.removeItem(key)
    }
  }
  const uploadToCloudinary = async (image) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('uploadToCloudinary image', image); // eslint-disable-line no-console
    }
    let imageUploadData = new FormData();
    imageUploadData.append("file", image);
    imageUploadData.append("upload_preset", "wam_uploads");
    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/reactdiv/image/upload/`,
        imageUploadData
      );
      const cloudinaryResponse = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.info('uploadToCloudinary: cloudinaryResponse', cloudinaryResponse); // eslint-disable-line no-console
      }
      return cloudinaryResponse.secure_url
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('uploadToCloudinary: err', err); // eslint-disable-line no-console
      }
      return err
    }
  }
  const uploadPrimaryImageToCloudinary = async (image) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('uploadPrimaryImageToCloudinary image', image); // eslint-disable-line no-console
    }
    const result = uploadToCloudinary(image).then(
      (data) => {
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.info('uploadPrimaryImageToCloudinary data', data); // eslint-disable-line no-console
        }
        return data
      }
    )
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('uploadPrimaryImageToCloudinary result', result); // eslint-disable-line no-console
    }
    return result
  }
  const uploadGalleryToCloudinary = async (gallery) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('uploadGalleryToCloudinary gallery', gallery); // eslint-disable-line no-console
    }
    const result = await Promise.all(gallery.map(async (image,
      index) => {
      return await uploadToCloudinary(image).then(
        (data) => {
          if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
            logger.info('uploadToCloudinary data', data); // eslint-disable-line no-console
          }
          return data
        }
      )
    }))
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('uploadGallerytoCloudainary result', result); // eslint-disable-line no-console
    }
    return result;
  }
  // NOTE END UTILS

  const userRegister = async (
    formData
  ) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('userRegister: formData', formData); // eslint-disable-line no-console
    }
    const send_data = {
      email: formData.email,
      password: formData.passwordConfirm
    }
    await adminEmailUserSignup(send_data)
    try {
      const response = await axios.post(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/users`,
        send_data
      );
      const data = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.info('userRegister: data', data); // eslint-disable-line no-console
      }
      if (data) {
        await userSignin(send_data)
      }
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('userRegister: err', err); // eslint-disable-line no-console
      }
      return err
    }
  }
  const userSignin = async (
    formData
  ) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('userSignin: formData', formData); // eslint-disable-line no-console
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: false
    }
    let request = {
      email: formData.email,
      password: formData.password
    }
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('userSignin: request', request); // eslint-disable-line no-console
    }
    try {
      const {data} = await axios.post(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/users/tokens`,
        request,
        config
      );
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.info('userSignin: data', data); // eslint-disable-line no-console
      }
      await setCookie('accessToken', JSON.stringify(data?.access_token), {
        path: '/',
        maxAge: 3600,
        sameSite: true
      })
      // await setCookie('refreshToken', JSON.stringify(data?.refresh_token), {
      //   path: '/',
      //   maxAge: 3600,
      //   sameSite: true
      // })
      if (data) {
        await getProfile(data.access_token)
      }
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('userSignin: err', err); // eslint-disable-line no-console
      }
      return err
    }
  }
  const userSignout = async () => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('userSignout'); // eslint-disable-line no-console
    }
    removeCookie('accessToken', {
      path: '/',
      sameSite: true,
    });
    // removeCookie('refreshToken', {
    //   path: '/',
    //   sameSite: true,
    // });
    await removeLocalStorageItem("profileData")
    await removeLocalStorageItem("profileFriendsData")
    await removeLocalStorageItem("profileInboxData")
    await removeLocalStorageItem("profileOutboxData")
    await removeLocalStorageItem("profileMessagesData")
    await removeLocalStorageItem("loggedInUserID")
    await router.refresh()
  }
  // NOTE Fake Update
  const getProfile = async (accessToken: boolean | any = false) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('getProfile accessToken', accessToken); // eslint-disable-line no-console
    }
    const config = {
      headers: {
        "Content-type": "application/json",
        "Authorization": `bearer ${accessToken && accessToken || cookie?.accessToken}`
      },
      withCredentials: true
    }
    try {
      const {data} = await axios.get(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/profiles/me`,
        config
      );
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('getProfile: data', data); // eslint-disable-line no-console
      }
      if (data.profile && data.profile.user_id) {
        await setLocalStorageItem("profileData", JSON.stringify(data.profile))
        // NOTE You do not really need the user_id specifically as far as I can tell
        await setLocalStorageItem("loggedInUserID", JSON.stringify(data.profile.user_id))
        await setProfileData(data.profile)
        await setLoggedInUser(data.profile.user_id)
      }
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('getProfile: err', err); // eslint-disable-line no-console
      }
    }
  };
  const getAllProfiles = async (accessToken: boolean | any = false) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('getAllProfiles accessToken', accessToken); // eslint-disable-line no-console
    }
    const config = {
      headers: {
        "Content-type": "application/json",
        "Authorization": `bearer ${accessToken && accessToken || cookie?.accessToken}`
      },
      withCredentials: true
    }
    try {
      const {data} = await axios.get(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/profiles/all`,
        config
      );
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('getAllProfiles: data', data); // eslint-disable-line no-console
      }
      data && setLiveWamUsers(data.profiles)
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('getProfile: err', err); // eslint-disable-line no-console
      }
    }
  }

  const filterUsers = async (formData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('filterUsers formData', formData); // eslint-disable-line no-console
    }
    let users;
    let usersByGender;
    let usersByAge = {profiles: []};
    let usersByLocation = {profiles: []};
    let selectedLat;
    let selectedLong;
    // NOTE Filter by Gender
    if (formData.genderFilter && (['Male', 'Female', 'Trans', 'Non-binary', 'Other'].includes(formData.genderFilter))) {
      console.log('testcookiething', WAM_COOKIE_CONFIG)
      const config = {
        headers: {
          "Content-type": "application/json",
          "Authorization": `bearer ${cookie?.accessToken}`
        },
        withCredentials: true
      }
      try {
        const response = await axios.get(
          process.env.NEXT_PUBLIC_WAM_API_URL + `wam/profiles/gender/${formData.genderFilter.toLowerCase()}`,
          config
        );
        console.log('filterUsers filter response', response)
        if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
          const {data} = response;
          usersByGender = data
          // usersByGender = await data.profiles.map((profile) =>
          //   normaliseProfile(profile)
          // ) ?? [];
        }
      } catch (err) {
        console.error(err);
      }
      users = usersByGender;
    } else {
      users = unfilteredUsersData;
    }
    // NOTE Filter by Age
    if (formData.ageFromFilter && formData.ageToFilter) {
      console.log('filterUsers ageFromFilter ageToFilter', formData.ageFromFilter, formData.ageToFilter)
      usersByAge.profiles = users.profiles.filter(object => {
        return eval(parseInt(formData.ageFromFilter) <= getAge(object.dob)) && eval(getAge(object.dob) <= parseInt(formData.ageToFilter));
      });
      users = usersByAge;
    }
    // NOTE Filter by Location & Distance
    if (formData.locationFilter && formData.distanceFilter) {
      console.log('filterUsers haslocationFilter and hasdistanceFilter')
      // NOTE Convert Location to Latitude & Longitude
      await getGeocode({address: formData.locationFilter}).then((results) => {
        console.log('filterUsers formData.locationFilter', formData.locationFilter)
        const {lat, lng} = getLatLng(results[0]);
        selectedLat = lat;
        selectedLong = lng;
        console.log('filterUsers selectedLat, selectedLong', selectedLat, selectedLong)
        // console.log("filterbylocation 📍 Coordinates: ", selectedLat, selectedLong);
      });
      console.log('filterUsers justbefore users', users)
      usersByLocation.profiles = users.profiles.filter(object => {
        // console.log('filterUsers formDatastuffs', formData.distanceFilter, formData.distanceFilter.replace("km",""))
        return getDistanceFromLatLonInKm(
          selectedLat,
          selectedLong,
          object.latitude,
          object.longitude
          // ) < formData.selectedDistanceKM;
        ) < formData.distanceFilter.replace("km", "");
      });
      users = usersByLocation;
    }
    // if (!formData.genderFilter && !formData.ageFromFilter && !formData.ageToFilter && !formData.locationFilter && !formData.distanceFilter) {
    //   users = unfilteredUsersData
    // }

    setUsersFiltered(true);
    setFilteredUsersData(users);
  }
  const readUsersFiltered = async (formData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('\n' +
        'readUsersFiltered()' +
        'formData:' + formData.genderSeeking
      ); // eslint-disable-line no-console
    }

    let users;
    let usersByGender;
    let selectedLat;
    let selectedLong;

    // console.log('searchusers genderseeking', formData.genderSeeking)
    if (
      !formData.genderSeeking ||
      (
        formData.genderSeeking &&
        (!['Male', 'Female', 'Trans', 'Non-binary', 'Other'].includes(formData.genderSeeking))
      )
      // formData.genderSeeking.includes(['Male','Female'])
    ) {
      console.log('searchusers gender any', allUsersData)
      users = allUsersData;
    } else if (
      formData.genderSeeking &&
      (['Male', 'Female', 'Trans', 'Non-binary', 'Other'].includes(formData.genderSeeking))
    ) {
      // NOTE Get Users By Gender
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${cookie.wam_access_token}`
          }
        };
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_WAM_API_URL}whereAdultsMeet/users/filtered/collect_by_gender/${formData.genderSeeking}`,
          config
        );
        if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
          const {data} = response;
          usersByGender = await data.data.map((user) =>
            normaliseUser(user)
          ) ?? [];
          console.log('searchusers gender', usersByGender)
        }
      } catch (err) {
        console.error(err);
      }
      users = usersByGender;
    }

    if (formData.selectedDistanceKM && formData.city) {
      // NOTE Convert Location to Latitude & Longitude
      await getGeocode({address: formData.city}).then((results) => {
        console.log('searchusers', formData.city)
        const {lat, lng} = getLatLng(results[0]);
        selectedLat = lat;
        selectedLong = lng;
        console.log('searchusers latlng', selectedLat, selectedLong)
        // console.log("filterbylocation 📍 Coordinates: ", selectedLat, selectedLong);
      });
      users = usersByGender.filter(object => {
        // console.log('searchusers usersByGender object.firstName', formData.selectedDistanceKM, formData.selectedDistanceKM.replace("km",""))
        return getDistanceFromLatLonInKm(
          selectedLat,
          selectedLong,
          object.latitude,
          object.longitude
          // ) < formData.selectedDistanceKM;
        ) < formData.selectedDistanceKM.replace("km", "");
      });
    }

    if (
      formData.selectedDistanceKM &&
      formData.city &&
      usersByGender
    ) {
      users = usersByGender.filter(object => {
        // console.log('searchusers usersByGender object.firstName', formData.selectedDistanceKM, formData.selectedDistanceKM.replace("km",""))
        return getDistanceFromLatLonInKm(
          selectedLat,
          selectedLong,
          object.latitude,
          object.longitude
          // ) < formData.selectedDistanceKM;
        ) < formData.selectedDistanceKM.replace("km", "");
      });
    } else if (
      formData.selectedDistanceKM &&
      formData.city
    ) {
      console.log('searchusers allUsersData filterbylocation inside allUsersData')
      users = allUsersData.filter(object => {
        // console.log('object.firstName', getDistanceFromLatLonInKm(
        //   selectedLat,
        //   selectedLong,
        //   object.latitude,
        //   object.longitude
        //   // ) < formData.selectedDistanceKM;
        // ))
        return getDistanceFromLatLonInKm(
          selectedLat,
          selectedLong,
          object.latitude,
          object.longitude
          // ) < formData.selectedDistanceKM;
        ) < formData.selectedDistanceKM.replace("km", "");
      });
    }

    console.log('searchusers before setFilteredUsersData', users)
    await setFilteredUsersData(users);
  }
  // NOTE Profile
  const resetProfileWizard = async (accessToken = false) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('resetProfileWizard accessToken', accessToken); // eslint-disable-line no-console
      logger.debug('resetProfileWizard cookie', cookie); // eslint-disable-line no-console
    }
    const config = {
      headers: {
        "Content-type": "application/json",
        "Authorization": `bearer ${accessToken && accessToken || cookie?.accessToken}`
      },
      withCredentials: true
    }
    let send_data = {
      something: "anything"
    }
    try {
      const response = await axios.put(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/profile/profile_wizard`,
        send_data,
        config
      );
      const data = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.info('resetProfileWizard: data', data); // eslint-disable-line no-console
      }
      await setLocalStorageItem("profileData", JSON.stringify(data.profile))
      await setProfileData(data.profile)
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('getProfile: err', err); // eslint-disable-line no-console
      }
    }
  };
  const updateProfile = async (formData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('updateProfile formData', formData); // eslint-disable-line no-console
    }
    let cloudinaryResponse = await uploadPrimaryImageToCloudinary(formData.primary_image)
    if (cloudinaryResponse?.name === 'AxiosError') {
      return cloudinaryResponse
    }
    let galleryCloudinaryResponse = await uploadGalleryToCloudinary(formData.gallery)
    if (galleryCloudinaryResponse) {
      await Promise.all(galleryCloudinaryResponse.map((response,
        index) => {
        if (response?.name === 'AxiosError') {
          return response
        }
      }))
    }

    const config = {
      headers: {
        "Content-type": "application/json",
        "Authorization": `bearer ${cookie?.accessToken}`
      },
      withCredentials: true
    }
    const send_data = {
      profile_wizard: true,
      first_name: formData.firstName && formData.firstName || undefined,
      last_name: formData.lastName && formData.lastName || undefined,
      dob: formData.dateOfBirth && formData.dateOfBirth || undefined,
      city: formData.city && formData.city || undefined,
      latitude: formData.selectedLocationData && formData.selectedLocationData?.latitude || undefined,
      longitude: formData.selectedLocationData && formData.selectedLocationData?.longitude || undefined,
      gender_identity: formData.genderIdentity && formData.genderIdentity.toLowerCase() || undefined,
      sexuality: formData.sexuality && formData.sexuality.toLowerCase() || undefined,
      relationship_status: formData.relationshipStatus && formData.relationshipStatus.toLowerCase() || undefined,
      tagline: formData.tagline && formData.tagline || undefined,
      biography: formData.biography && formData.biography || undefined,
      primary_image: cloudinaryResponse,
      images: galleryCloudinaryResponse
    }
    await adminEmailUserRegistration(send_data)
    try {
      const response = await axios.put(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/profiles`,
        send_data,
        config
      );
      const data = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.info('updateProfile: data', data); // eslint-disable-line no-console
      }
      await setLocalStorageItem("profileData", JSON.stringify(data.profile))
      setProfileData(data.profile)
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('updateProfile: err', err); // eslint-disable-line no-console
      }
      return err
    }
  };
  const editProfileTagline = async (formData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('updateProfile',); // eslint-disable-line no-console
    }
    const config = {
      headers: {
        "Content-type": "application/json",
        "Authorization": `bearer ${cookie?.refreshToken?.access_token}`
      },
      withCredentials: true
    }
    let send_data = {
      tagline: formData.tagline
    }
    try {
      const response = await axios.put(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/profile/tagline`,
        send_data,
        config
      );
      const data = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.info('updateProfile: data', data); // eslint-disable-line no-console
      }
      await setLocalStorageItem("profileData", JSON.stringify(data.profile))
      setProfileData(data.profile)
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('updateProfile: err', err); // eslint-disable-line no-console
      }
    }
  }
  const editProfileBiography = async (formData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('editProfileBiography',); // eslint-disable-line no-console
    }
    const config = {
      headers: {
        "Content-type": "application/json",
        "Authorization": `bearer ${cookie?.accessToken}`
      },
      withCredentials: true
    }
    let send_data = {
      biography: formData.biography
    }
    try {
      const response = await axios.put(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/profile/biography`,
        send_data,
        config
      );
      const data = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.info('editProfileBiography: data', data); // eslint-disable-line no-console
      }
      await setLocalStorageItem("profileData", JSON.stringify(data.profile))
      setProfileData(data.profile)
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('editProfileBiography: err', err); // eslint-disable-line no-console
      }
    }
  }
  const editProfileDetails = async (formData) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('editProfileDetails',); // eslint-disable-line no-console
    }
    const config = {
      headers: {
        "Content-type": "application/json",
        "Authorization": `bearer ${cookie?.refreshToken?.access_token}`
      },
      withCredentials: true
    }
    let send_data = {
      gender_identity: formData.genderIdentity && formData.genderIdentity.toLowerCase() || undefined,
      sexuality: formData.sexuality && formData.sexuality.toLowerCase() || undefined,
      relationship_status: formData.relationshipStatus && formData.relationshipStatus.toLowerCase() || undefined,
    }
    try {
      const response = await axios.put(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/profile/details`,
        send_data,
        config
      );
      const data = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.info('editProfileDetails: data', data); // eslint-disable-line no-console
      }
      await setLocalStorageItem("profileData", JSON.stringify(data.profile))
      setProfileData(data.profile)
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('editProfileDetails: err', err); // eslint-disable-line no-console
      }
    }
  }
  const editProfileLikes = async (userID,
    likedUserID) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('editProfileLikes userID, likedUserID', userID, likedUserID); // eslint-disable-line no-console
    }
    const config = {
      headers: {
        "Content-type": "application/json",
        "Authorization": `bearer ${cookie?.accessToken}`
      },
      withCredentials: true
    }
    let send_data = {
      user_id: userID,
      liked_user_id: likedUserID
    }
    try {
      const response = await axios.put(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/profile/likes`,
        send_data,
        config
      );
      const data = response?.data;
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.info('editProfileLikes: data', data); // eslint-disable-line no-console
      }
      await setLocalStorageItem("profileData", JSON.stringify(data.profile))
      await setLocalStorageItem("loggedInUserID", JSON.stringify(data.profile.user_id))
      await setProfileData(data.profile)
      await setLoggedInUser(data.profile.user_id)
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('editProfileLikes: err', err); // eslint-disable-line no-console
      }
    }
  }
// NOTE Messages
  const getAllProfileMessages = async () => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('getAllProfileMessages'); // eslint-disable-line no-console
    }
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          "Authorization": `bearer ${cookie?.accessToken}`
        },
        withCredentials: true
      }
      // NOTE Outbox
      const outbox = await axios.get(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/messages/sent`,
        config
      );
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('getAllProfileMessages: wam/messages/sent outbox', outbox); // eslint-disable-line no-console
        logger.debug('getAllProfileMessages: wam/messages/sent outbox?.data', outbox?.data); // eslint-disable-line no-console
      }
      setUserOutbox(outbox?.data)
      setLocalStorageItem("getAllProfileMessages profileOutboxData", JSON.stringify(outbox?.data))
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        // logger.debug('getAllProfileMessages: groupedOutboxData', groupedOutboxData); // eslint-disable-line no-console
      }
      // NOTE Inbox
      const inbox = await axios.get(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/messages/received`,
        config,
      );
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('getAllProfileMessages: inboxData', inbox); // eslint-disable-line no-console
        logger.debug('getAllProfileMessages: inboxData', inbox?.data); // eslint-disable-line no-console
      }
      setUserInbox(inbox?.data)
      setLocalStorageItem("getAllProfileMessages profileInboxData", JSON.stringify(inbox?.data))
      setAllUserMessages({
        ...inbox?.data,
        ...outbox?.data
      })
      setLocalStorageItem("getAllProfileMessages profileMessagesData", JSON.stringify({
        ...inbox?.data,
        ...outbox?.data
      }))
      console.log('getAllProfileMessages: hasthishadtime',activeFriendData)
      setDirectMessageData(filter(allUserMessages, (message) => {
        return (message.user_id = profileData.user_id && message.friend_id == activeFriendData.user_id) || (message.user_id == activeFriendData.user_id && message.friend_id == profileData.user_id)
      }))
      console.log('getAllProfileMessages: hasthishadtime2',filter(allUserMessages, (message) => {
        return (message.user_id = profileData.user_id && message.friend_id == activeFriendData.user_id) || (message.user_id == activeFriendData.user_id && message.friend_id == profileData.user_id)
      }))

      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        // logger.debug('getAllProfileMessages: groupedInboxData', groupedInboxData); // eslint-disable-line no-console
      }
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('getAllProfileMessages err:', err); // eslint-disable-line no-console
      }
      return false;
    }
  }
  const sendDirectMessage = async (
    formData,
    data
  ) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      console.log('sendmessagetest sendDirectMessage formData', formData)
      console.log('sendmessagetest sendDirectMessage data', data)
      // logger.debug('\n' +
      //   'updateUserAboutMe(formData, userId)' + '\n' +
      //   'loggedInUser:' + loggedInUser + '\n' +
      //   'messagedUser:' + data.messagedUser + '\n' +
      //   'formData:' + formData
      // ); // eslint-disable-line no-console
    }

    // console.log('wstest2 sendDirectMessage websocket', websocket)
    // if (websocket !== undefined) {
    //   console.log('wstest websocket was not undefined and should send')
    // chatNotification(data.loggedInUser, data.messagedUser)

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          "Authorization": `bearer ${cookie?.accessToken}`
        },
        withCredentials: true
      }
      const preparedData = {
        friend_id: userFriendsData && userFriendsData[0]?.user_id,
        message_status: "sent",
        message_type: "text",
        message_condition: "original",
        message_content: formData.message_content
      };
      console.log('sendmessagetest preparedData', preparedData)
      const response = await axios.put(
        process.env.NEXT_PUBLIC_WAM_API_URL + `wam/messages`,
        preparedData,
        config,
      );
      console.log('sendmessagetest response', response)
      if (response.status === 201) {  // response - object, eg { status: 200, message: 'OK' }
        const {data} = response;
        if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
          logger.debug('sendmessagetest success sendDirectMessage data', data); // eslint-disable-line no-console
        }
        await readAllMessages()
        // await readDirectMessages(data.loggedInUser, data.messagedUser)
      }
      return false;
    } catch (err) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.error('error:', err); // eslint-disable-line no-console
      }
      return false;
    }
  }; // NOTE v1.0
  // Websocket


  // Websocket
  // const connectToChat = async () => {
  //   setWebsocket(new WebSocket("ws://localhost:8081/ws"))
  // }

  const connectChatWebsocket = async () => {
    const url = "ws://localhost:8081/ws/" + loggedInUser;
    // const url = "ws://localhost:8081/ws";
    const ws = await new WebSocket(url);
    const object = `{"user": "${loggedInUser}", "url": "#currenturl"}`
    ws.onopen = async (event) => {
      ws.send(object);
      await handleWebsocketResponse(ws)
    };
    await setWebsocket(ws)
    return () => ws.close()
  }
  const handleWebsocketResponse = async (ws) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('\n' +
        'handleWebsocketResponse()'
      ); // eslint-disable-line no-console
    }
    ws.onmessage = (e) => {
      let data;
      console.log('websocket: data was received as event (e)', e)
      if (e.data.includes("says:")) {

      } else {
        data = JSON.parse(e.data);
        console.log('websocket: data was parsed', data)
      }

      if (data.recipient == `${loggedInUser}`) {
        alert('you have received a new message')
      }
      // setMessages([...messages, message]);
    };
  }
  const chatNotification = async (sender,
    recipient) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('\n' +
        'chatNotification(sender, recipient)' + '\n' +
        'sender:' + sender + '\n' +
        'recipient:' + recipient
      ); // eslint-disable-line no-console
    }
    const object = `{"sender": "${sender}", "recipient": "${recipient}"}`
    websocket.send(object)
  }

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('wamAPIProvider useEffect[profileData]'); // eslint-disable-line no-console
    }
    let profileFriendsData;
    if (profileData && wamUsers) {
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('wamAPIProvider useEffect[profileData] wamUsers', wamUsers)
        logger.debug('wamAPIProvider useEffect[profileData] wamUsers?.profiles', wamUsers?.profiles)
        logger.debug('wamAPIProvider useEffect[profileData] JSON.parse(localStorage.getItem(\'profileData\'))?.interested', JSON.parse(localStorage.getItem('profileData'))?.interested)
        logger.debug('wamAPIProvider useEffect[profileData] setUserFriendsData', filter(wamUsers?.profiles, (profile) => {
          return includes(JSON.parse(localStorage.getItem('profileData'))?.interested, profile.user_id)
        })); // eslint-disable-line no-console
      }
      profileFriendsData = filter(wamUsers?.profiles, (profile) => {
        return includes(profileData?.interested, profile.user_id)
      })
    }
    if (profileFriendsData) {
      setProfileFriendsData(profileFriendsData)
      setActiveFriendData(profileFriendsData[0])
      setLocalStorageItem("profileFriendsData", JSON.stringify(profileFriendsData))
      getAllProfiles()
      getAllProfileMessages()
    }
  }, [profileData, wamUsers])

  useEffect(() => {
    if (allUserMessages && activeFriendData) {
      console.log('setDirectMessageData test allUserMessages', allUserMessages)
      console.log('setDirectMessageData test profileData.user_id', profileData.user_id)
      console.log('setDirectMessageData test activeFriendData.user_id', activeFriendData.user_id)
      console.log('setDirectMessageData test filter', filter(allUserMessages, (message) => {
        return (message.user_id = profileData.user_id && message.friend_id == activeFriendData.user_id) || (message.user_id == activeFriendData.user_id && message.friend_id == profileData.user_id)
      }))
      setDirectMessageData(filter(allUserMessages, (message) => {
        return (message.user_id = profileData.user_id && message.friend_id == activeFriendData.user_id) || (message.user_id == activeFriendData.user_id && message.friend_id == profileData.user_id)
      }))
    }
  }, [activeFriendData])

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      let galleryTemp = localStorage.getItem('gallery')
      // console.log('testertest gallery', gallery)
      // console.log('testertest gallery JSON.parse(galleryTemp)', JSON.parse(galleryTemp))
      // console.log('testertest gallery JSON.parse(galleryTemp) != galleryTemp', JSON.parse(galleryTemp).toString() !== gallery.toString())
      if (JSON.parse(galleryTemp) !== gallery) {
        setGallery(JSON.parse(galleryTemp))
      }
      let primaryImageTemp;
      if (localStorage.getItem('primaryImage') !== null) {
        primaryImageTemp = localStorage.getItem('primaryImage').replace(/['"]+/g, '')
        console.log('testertest primaryImageTemp', primaryImageTemp)
      }
      if (primaryImageTemp && primaryImage && primaryImageTemp.toString() !== primaryImage.toString()) {
        setPrimaryImage(primaryImageTemp)
      }
    }
  }, [
    // gallery,
    primaryImage,
    // profileData,
    // loggedInUser
  ])

  const value = {
    setLocalStorageItem,
    hydrationLoading,
    setHydrationLoading,
    unfilteredWamUsers,
    setUnfilteredWamUsers,
    filteredWamUsers,
    setFilteredWamUsers,
    liveWamUsers,
    setLiveWamUsers,
    profileData,
    profileFriendsData,
    activeFriendData,
    setActiveFriendData,

    userInbox,
    userOutbox,
    allUserMessages,
    directMessageData,

    gallery,
    setGallery,
    primaryImage,
    setPrimaryImage,
    registeringUserPrimaryImage,
    setRegisteringUserPrimaryImage,

    userRegister,
    userSignin,
    userSignout,
    getProfile,
    resetProfileWizard,
    filterUsers,
    updateProfile,
    editProfileTagline,
    editProfileBiography,
    editProfileDetails,
    editProfileLikes,

    readUsersFiltered,
    sendDirectMessage,

    usersDataFilteredByLocationAndDistance,
    websocket,
  };

  return (
    <>
      <WamApiContext.Provider
        value={value}
      >
        {children}
      </WamApiContext.Provider>
    </>
  );

}
