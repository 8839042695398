import {ReactNode, useState, useEffect} from 'react';
import {reactDivAPIContext as ReactDivAPIContext} from '@/modules/core/reactDivAPI/contexts/reactDivAPIContext';
import axios from 'axios';
import {useCookies} from 'react-cookie';
import {ReactDivAPIProvider as ReactDivAPIProviderProps, ReactDivFields} from '@/modules/core/reactDivAPI/types/ReactDivAPI';

export function ReactDivAPIProvider({
  reactDivClientID,
  reactDivClientProjectID,
  allUsers = [],
  allProjects = [],
  allShells = [],
  allFields = [],
  allFieldSettings = [],
  allFieldValidations = [],
  allFieldAdvanced = [],
  children
}: ReactDivAPIProviderProps) {

  const [cookie] = useCookies(['access_token']);
  // Users
  const [allUsersData, setAllUsersData] = useState(allUsers); // Dashboard Only
  const [selectedUserData, setSelectedUserData] = useState(); // Dashboard Only
  const [selectedUserID, setSelectedUserID] = useState(); // Dashboard Only
  // Web Module
  // Projects
  const [userCreatedProjects, setUserCreatedProjects] = useState(allProjects);
  const [userCreatedProjectsMenuItems, setUserCreatedProjectsMenuItems] = useState([]);
  const [activeProject, setActiveProject] = useState('');
  // Shells
  const [userCreatedShells, setUserCreatedShells] = useState(allShells);
  const [userCreatedShellsMenuItems, setUserCreatedShellsMenuItems] = useState([]);
  const [activeShell, setActiveShell] = useState();
  const [activeShellID, setActiveShellID] = useState();
  const [activeSystemShell, setActiveSystemShell] = useState();
  const [activeSystemShellID, setActiveSystemShellID] = useState();
  // Fields
  const [reactDivFields, setReactDivFields] = useState(allFields);
  const [reactDivFieldSettings, setReactDivFieldSettings] = useState(allFieldSettings);
  const [reactDivFieldValidations, setReactDivFieldValidations] = useState(allFieldValidations);
  const [reactDivFieldAdvanced, setReactDivFieldAdvanced] = useState(allFieldAdvanced);

  const [activeReactDivField, setActiveReactDivField] = useState(false);
  const [activeReactDivFieldSettings, setActiveReactDivFieldSettings] = useState(allFieldSettings);
  const [activeReactDivFieldValidations, setActiveReactDivFieldValidations] = useState(allFieldValidations);
  const [activeReactDivFieldAdvanced, setActiveReactDivFieldAdvanced] = useState(allFieldAdvanced);
  // Shelled Fields
  const [currentAddReactionState, setCurrentAddReactionState] = useState('');
  const [allShelledFields, setAllShelledFields] = useState();
  const [allSystemShelledFields, setAllSystemShelledFields] = useState();
  // const [systemShelledFieldData, setSystemShelledFieldData] = useState(systemShelledFieldData)

  // Users
  const createUser = async (formData) => {
    console.log('submitted - creating user', formData);
    // verify user client_id is available
    try {

      const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/user/create/`, {
        credentials: {
          username: formData.username,
          email: formData.email,
          password: formData.password,
          status: 'pending',
          type: formData.type
        },
        profile: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          company: formData.company,
          url: formData.url
        },
      }, {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      });

      const data = response?.data;
      // const accessToken = data?.access_token;
      console.log('data', data);
      readUsers();
    } catch (err) {
      console.log(err);
    }
  };
  const readUsers = async () => {
    console.log('readUsers');
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${cookie.access_token}`
        }
      };
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/users/collect/`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('success stuff', response)
        const {data} = response;
        // console.log('more success stuff', data)
        setAllUsersData(data.data);
        return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };
  const readUser = async (userID) => {
    console.log('iamthevalue readUser userID', userID);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${cookie.access_token}`
        }
      };
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/user/collect/${userID}`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        console.log('iamthevalue success stuff', response);
        const {data} = response;
        console.log('iamthevalue more success stuff', data);
        setSelectedUserData(data.data);
        return data.data;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };
  // Web Module
  // Projects
  const createProject = async (formData) => {
    console.log('submitted - creating project', formData);
    // verify user client_id is available
    try {

      const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/projects/create/`, {
        ownership: {
          //@ts-ignore
          client_id: `${cookie.client_id}`
        },
        domain: formData.domain,
        company: formData.company,
      }, {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      });

      const data = response?.data;
      // const accessToken = data?.access_token;
      console.log('data', data);
      readProjects();
    } catch (err) {
      console.log(err);
    }
  };
  const readProjects = async () => {
    console.log('fetching all projects from reactDiv api');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/projects/`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('success stuff', response)
        const {data} = response;
        // console.log('more success stuff', data)
        setUserCreatedProjects(data.data);
        return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };
  // Shells
  const createShell = async (formData) => {
    // console.log('clicked - creating shell', formData);
    console.log('time now');
    const date = new Date();
    const year = date.getFullYear();
    let month: number | string = date.getMonth()+1;
    let day: number | string = date.getDate();

    if (day<10) {
      day = '0' + day;
    }
    if (month<10) {
      month = '0' + month;
    }
    // console.log(year+'-' + month + '-'+day);
    let hours: number | string = date.getHours();
    if (hours<10) {
      hours = '0' + hours;
    }
    let minutes: number | string = date.getMinutes();
    const seconds = date.getSeconds();
    if (minutes<10) {
      minutes = '0' + minutes;
    }
    const milliseconds = date.getMilliseconds();
    // console.log(year+'-' + month + '-'+day + 'T' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds + '+00:00');
    // return
    // console.log('time now', Date())
    // return
    try {

      const submitData = {
        ownership: {
          system: false,
          client_id: '630dfcbd97f8835a69f9f991', // TODO not hardcode currently vincent
          project_id: '630a4605c7703c23a8cc9a84', // TODO not hardcode currently central brisbane dental
        },
        type: formData.type,
        name: formData.name,
        singleApiId: formData.singleApiId,
        groupApiId: formData.groupApiId,
        description: formData.description && formData.description || null,
        created_at: year+'-' + month + '-'+day + 'T' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds + '+00:00',
        created_by: '630a30aa4c28afa2b58a28a6',  // TODO not hardcode currently josh
        updated_at: year+'-' + month + '-'+day + 'T' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds + '+00:00',
        updated_by: '630a30aa4c28afa2b58a28a6',  // TODO not hardcode currently josh
        published_at: year+'-' + month + '-'+day + 'T' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds + '+00:00', // 2022-09-04T23:53:37.251+00:00
        published_by: '630a30aa4c28afa2b58a28a6'  // TODO not hardcode currently josh
      };
      console.log('createShell submitdata', submitData);
      const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shell/create`, submitData, {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      });

      const data = response?.data;
      // const accessToken = data?.access_token;
      console.log('data', data);
      readShells();
    } catch (err) {
      console.log(err);
    }
  }; // NOTE Dashboard Only
  const readShells = async () => {
    console.log('readShells');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shells/collect/`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('success stuff', response)
        const {data} = response;
        // console.log('more success stuff', data)
        setUserCreatedShells(data.data);
        return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  }; // NOTE Dashboard Only
  const readShell = async (singuleAPIID) => {
    console.log('readShell');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shell/collect_by_singular_api_id/${singuleAPIID}`, config);
      // console.log('readShell reseponse', response)
      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('readShell success stuff', response)
        const {data} = response;
        console.log('readShell more success stuff', data);
        setActiveShell(data.data);
        setActiveShellID(data.data._id);

        // return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  }; // NOTE Dashboard Only
  // NOTE Dashboard Only ADMIN

  const readSystemShell = async (singularAPIID) => {
    console.log('readSystemShell');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/systemShell/collect_by_singular_api_id/${singularAPIID}`, config);
      // console.log('readShell reseponse', response)
      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('readShell success stuff', response)
        const {data} = response;
        // console.log('readShell more success stuff', data)
        setActiveSystemShell(data.data);
        setActiveSystemShellID(data.data._id);

        // return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  }; // NOTE Dashboard Only
  // NOTE Dashboard Only ADMIN
  const createSystemShell = async (formData) => {
    console.log('clicked - creating system shell', formData);
    // return
    try {

      const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/systemShell/create`, {
        ownership: {
          client_id: '630dfcbd97f8835a69f9f991', // TODO not hardcode currently vincent
          project_id: '630a4605c7703c23a8cc9a84', // TODO not hardcode currently central brisbane dental
        },
        type: formData.type, // NOTE From List
        name: formData.name,
        singularAPIID: formData.singularAPIID,
        pluralisedAPIID: formData.pluralisedAPIID,
        description: formData.description && formData.description || null,
        dependencyFor: formData.dependencyFor, // NOTE From List
        dependencyType: formData.dependencyType, // NOTE From List
      }, {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      });

      const data = response?.data;
      // const accessToken = data?.access_token;
      console.log('data', data);
      // readShells()
    } catch (err) {
      console.log(err);
    }
  };
  // Fields
  const createField = async () => {

  };
  const readFields = async () => {
    console.log('readFields');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.REACTDIV_API_URL}reactDiv/web/field`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('success stuff', response)
        const {data} = response;
        console.log('more success stuff', data);
        setReactDivFields(data.data);
        return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };
  const readFieldSettings = async () => {
    console.log('readFields');
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${cookie.access_token}`
        }
      };
      const response = await axios.get(`${process.env.REACTDIV_API_URL}reactDiv/web/field/settings/`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('success stuff', response)
        const {data} = response;
        console.log('web field settings', data);
        setReactDivFieldSettings(data.data);
        return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };
  // Shelled Fields?
  const createShelledFields = async (formData, event, additionalData) => {
    console.log('clicked - creating shelled field', formData);
    console.log('clicked - creating shelled field - event', event);
    console.log('clicked - creating shelled field - variable', additionalData);

    const preparedData = {
      fieldType: formData.fieldType,
      ownership: {
        // @ts-ignore
        // "client_id": cookie.client_id TODO Not Hard code currently vincent central brisbane dental make it the shell id for the current page for ease of web dev
        system: false,
        client_id: '630dfcbd97f8835a69f9f991',
        project_id: '630a4605c7703c23a8cc9a84',
        shell_id: additionalData.shell_id
      },
      settings: {
        displayName: formData.displayName,
        apiID: formData.apiID,
        description: formData.description,
        // useAsTitleField: formData.useAsTitle,
        allowMultipleValues: formData.allowMultipleValues,
        // localizeField: formData.localizeField
      },
      // validations: {
      //   makeFieldRequired: formData.makeFieldRequired,
      //   setFieldAsUnique: formData.setFieldAsUnique,
      //   limitCharacterCount: formData.limitCharacterCount,
      //   matchASpecificPattern: formData.matchASpecificPattern,
      //   restrictASpecificPattern: formData.restrictASpecificPattern,
      // },
      // advanced: {
      //   setInitialValue: formData.setInitialValue,
      //   visibility: formData.fieldVisibility,
      // }
    };
    console.log('prepared - creating shelled field', preparedData);
    // return
    try {

      const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shelled_field/create`, preparedData, {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      });

      const data = response?.data;
      // const accessToken = data?.access_token;
      console.log('shelled field data', data);
      readShelledFields();
    } catch (err) {
      console.log(err);
    }
  };
  const readShelledFields = async () => {
    console.log('readShelledFields');
    try {
      const config = {headers: {Authorization: `Bearer ${cookie.access_token}`}};
      const response = await axios.get(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/shelled_field/collect_all/`, config);

      if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
        // console.log('success stuff', response)
        const {data} = response;
        // console.log('more success stuff', data)
        setAllShelledFields(data.data);
        return true;
      }
      return false;

    } catch (err) {
      console.error(err);
      return false;
    }
  };
  const createSystemShelledFields = async (formData, event, additionalData) => {
    console.log('clicked - creating system shelled field', formData);
    console.log('clicked - creating system shelled field - event', event);
    console.log('clicked - creating system shelled field - variable', additionalData);

    const preparedData = {
      ownership: {
        // @ts-ignore
        // "client_id": cookie.client_id TODO Not Hard code currently vincent central brisbane dental make it the shell id for the current page for ease of web dev
        // "client_id": "630dfcbd97f8835a69f9f991",
        // "project_id": "630a4605c7703c23a8cc9a84",
        system_shell_id: activeSystemShellID
      },
      settings: {
        displayName: formData.displayName,
        apiID: formData.apiID,
        description: formData.description,
        useAsTitleField: formData.useAsTitle,
        allowMultipleValues: formData.allowMultipleValues,
        localizeField: formData.localizeField
      },
      validations: {
        makeFieldRequired: formData.makeFieldRequired,
        setFieldAsUnique: formData.setFieldAsUnique,
        limitCharacterCount: formData.limitCharacterCount,
        matchASpecificPattern: formData.matchASpecificPattern,
        restrictASpecificPattern: formData.restrictASpecificPattern,
      },
      advanced: {
        setInitialValue: formData.setInitialValue,
        visibility: formData.fieldVisibility,
      },
      fieldType: activeReactDivField
    };
    console.log('prepared - creating system shelled field', preparedData);
    // return
    try {

      const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/system_shelled_field/create`, preparedData, {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      });

      const data = response?.data;
      // const accessToken = data?.access_token;
      console.log('system shelled field data', data);
      // readShells()
    } catch (err) {
      console.log(err);
    }
  };


  const createContent = async (formData) => {
    console.log('submitted - creating content entry', formData);
    // verify user client_id is available
    const contentDataList = [];
    Object.keys(formData).map((inputName, index) => {
      console.log('inputsare', inputName, formData, formData[inputName]);
      contentDataList.push({
        shelled_field_id: inputName,
        value: formData[inputName],
      });
    });

    try {
      const response = await axios.post(`${process.env.NEXT_PUBLIC_REACTDIV_API_URL}reactDiv/web/content/`, {
        ownership: {
          client_id: '630dfcbd97f8835a69f9f991', // TODO not hardcode currently vincent
          project_id: '630a4605c7703c23a8cc9a84', // TODO not hardcode currently central brisbane dental
          shell_id: '',
        },
        contentData: contentDataList
        // value: formData.,
      }, {
        headers: {Authorization: `Bearer ${cookie.access_token}`}
      });
      const data = response?.data;
      // const accessToken = data?.access_token;
      console.log('data', data);

    } catch (err) {
      console.log(err);
    }
  };

  const updateActiveFieldData = () => {

    let settingsTabInputData;
    let validationsTabInputData;
    let advancedTabInputData;
    console.log('updatingActiveFieldData', reactDivFieldSettings);
    reactDivFieldSettings.map((fieldSettings, index) => {
      if (fieldSettings.name === activeReactDivField) {
        console.log('yes the name matches', fieldSettings);
        settingsTabInputData = fieldSettings.properties; // properties named inputs and fieldOptions. This is a tabs inputs.
      }
    });
    console.log('testing ', settingsTabInputData);
    setActiveReactDivFieldSettings(settingsTabInputData);

    reactDivFieldValidations.map((fieldValidation, index) => {
      if (fieldValidation.name === activeReactDivField) {
        console.log('yes the name matches', fieldValidation);
        validationsTabInputData = fieldValidation.properties; // properties named inputs and fieldOptions. This is a tabs inputs.
      }
    });
    console.log('testing ', validationsTabInputData);
    setActiveReactDivFieldValidations(validationsTabInputData);

    reactDivFieldAdvanced.map((fieldAdvanced, index) => {
      if (fieldAdvanced.name === activeReactDivField) {
        console.log('yes the name matches', fieldAdvanced);
        advancedTabInputData = fieldAdvanced.properties; // properties named inputs and fieldOptions. This is a tabs inputs.
      }
    });
    console.log('testing ', advancedTabInputData);
    setActiveReactDivFieldAdvanced(advancedTabInputData);

  };

  useEffect(() => {
    console.log('iamthevalue useeffect', selectedUserID);
    if(selectedUserID) {
      const fetchData = async () => {
        const data = await readUser(selectedUserID);
        console.log('iamthevalue useeffect data', data);
        // @ts-ignore
        setSelectedUserData(data);
      };
      fetchData().catch(console.error);
    }
  }, [selectedUserID]);

  useEffect(() => {
    console.log('iamthevalue and must of changed', selectedUserData);
  }, [selectedUserData]);

  useEffect(() => {
    console.log('the active project is ', activeProject);
  }, [activeProject]);
  /*
   * onLoad we get the userCreatedShells from the reactDivAPI and determine the menu items.
   * This hook is dependent on the userCreatedShells
   *
   * XXX When a new shell is created, a function readShells will run, this will update the data for userCreatedShells and trigger this hook to fire again.
   *
   */
  useEffect(() => {
    // readShells();
    // readFields();
    // readFieldSettings();
    console.log('project menu items', userCreatedProjects);
    if (userCreatedProjects.length > 0) {
      const projectsMenuItems = [];
      console.log('got here');
      userCreatedProjects.map((userCreatedProject, index) => {
        console.log('got also to here', userCreatedProject);
        projectsMenuItems.push({
          type: 'client',
          position: index,
          label: userCreatedProject.domain
        });
      });
      setUserCreatedProjectsMenuItems(projectsMenuItems);
    }

  }, [userCreatedProjects]);

  /*
   * onLoad we get the userCreatedShells from the reactDivAPI and determine the menu items.
   * This hook is dependent on the userCreatedShells
   *
   * XXX When a new shell is created, a function readShells will run, this will update the data for userCreatedShells and trigger this hook to fire again.
   *
   */
  useEffect(() => {
    // readShells();
    // readFields();
    // readFieldSettings();
    console.log('repair menu items', userCreatedShells);
    if (userCreatedShells.length > 0) {
      const shellsMenuItems = [];
      console.log('got here');
      userCreatedShells.map((userCreatedShell, index) => {
        console.log('got also to here userCreatedShell', userCreatedShell);
        shellsMenuItems.push({
          name: userCreatedShell.singleApiId,
          label: userCreatedShell.name,
          destination: userCreatedShell.singleApiId,
        });
      });
      setUserCreatedShellsMenuItems(shellsMenuItems);
    }

  }, [userCreatedShells]);

  /*
     * onLoad we skip the hook by relying on the default value `false` being set on the state variable `activeReactDivField`.
     * This field becomes active when a user clicks a field in the Dashboard.
     *
     * When the user selects a Field, the state will change causing the hook to fire again.
     *
     */
  useEffect(() => {
    if (activeReactDivField) {
      console.log('activeReactDivField', activeReactDivField);
      updateActiveFieldData();
    } else {
      console.log('activeReactDivField will be false on first load', activeReactDivField);
    }
  }, [activeReactDivField]);


  const value = {
    // Users
    createUser,
    readUsers,
    readUser,
    allUsersData,
    selectedUserData,
    selectedUserID,
    setSelectedUserID,
    // Web Module
    // Projects
    createProject,
    userCreatedProjects,
    userCreatedProjectsMenuItems,
    activeProject,
    setActiveProject,
    // Shells
    createShell,
    readShell,
    userCreatedShells,
    userCreatedShellsMenuItems,
    activeShellID,
    setActiveShellID,
    createSystemShell,

    readSystemShell,
    activeSystemShellID,
    setActiveSystemShellID,
    // Fields
    reactDivFields,
    reactDivFieldSettings,
    reactDivFieldValidations,
    reactDivFieldAdvanced,
    activeReactDivField,
    setActiveReactDivField,
    activeReactDivFieldSettings,
    activeReactDivFieldValidations,
    activeReactDivFieldAdvanced,
    // Shelled Fields
    createShelledFields,
    allShelledFields,
    createSystemShelledFields,
    // Dashboard
    currentAddReactionState,
    setCurrentAddReactionState,

    // Content
    createContent
  };

  return (
    <>
      <ReactDivAPIContext.Provider
        value={value}
      >
        {children}
      </ReactDivAPIContext.Provider>
    </>
  );

}
