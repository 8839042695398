import {ReactNode, useState, useEffect} from 'react';
import {userSessionContext as UserSessionContext} from '@/modules/core/userSession/contexts/userSessionContext';
import axios from 'axios';
import Router from 'next/router';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {useCookies} from 'react-cookie';
import {createLogger} from "@/modules/core/logging/logger";
import useWamAPI from "@/modules/core/wamAPI/hooks/useWamAPIProvider";

const fileLabel = 'modules/core/userSession/providers/userSessionProvider.tsx';
const logger = createLogger({
  fileLabel,
});

type ProviderProps = {
  children: ReactNode;
}

export function UserSessionProvider({children}: ProviderProps) {
  const [cookie, setCookie, removeCookie] = useCookies([
    'tokens',
    'refreshToken',
    'wam_access_token',
    'wam_user_id',
    'wam_logged_in_user'
  ]);
  // const {
  //   getProfile
  // } = useWamAPI()

  // const registerUser = async (
  //   formData,
  //   data
  // ) => {

  //   if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
  //     logger.debug('registerUser formData', formData); // eslint-disable-line no-console
  //     logger.debug('registerUser data', data); // eslint-disable-line no-console
  //   }
  //
  //   // let latitude;
  //   // let longitude;
  //
  //   await handleEmailsUserRegistration(formData)
  //
  //   await getGeocode({address: formData.city}).then((results) => {
  //     const {lat, lng} = getLatLng(results[0]);
  //     latitude = lat;
  //     longitude = lng;
  //     console.log("📍 Coordinates: ", {lat, lng});
  //   });
  //
  //   try {
  //     const response = await axios.post(process.env.NEXT_PUBLIC_WAM_API_URL + 'whereAdultsMeet/user/create', {
  //       credentials: {
  //         username: formData.username,
  //         type: "user",
  //         status: "unverified", // This needs a photo ID
  //         email: formData.emailAddress,
  //         password: formData.password,
  //       },
  //       profile: {
  //         firstName: formData.firstName,
  //         lastName: formData.lastName,
  //         dateOfBirth: formData.dateOfBirth,
  //         genderIdentity: formData.genderIdentity,
  //         sexualOrientation: formData.sexualOrientation,
  //         relationshipStatus: formData.relationshipStatus,
  //         city: formData.city,
  //         latitude: latitude,
  //         longitude: longitude
  //       },
  //       about: {
  //         tagline: formData.tagline,
  //         biography: formData.shortDes
  //       }
  //     });
  //     const newUserID = response?.data;
  //
  //     // const signature = await getSignature();
  //     // const cloudinaryResponse = await axios.post(endPoints.uploadEndpoint, {
  //     //   data: {
  //     //     // apiKey: baseConfig.apiKey,
  //     //     // timestamp: baseConfig.timestamp,
  //     //     // signature: signature,
  //     //     file: formData.profilePicture[0],
  //     //     upload_preset: 'wam_uploads',
  //     //     // eager: "w_400,h_300,c_pad|w_260,h_200,c_crop",
  //     //   },
  //     // });

  //     // NOTE Upload Image to Cloudinary
  //     let imageData = new FormData();
  //     imageData.append("file", formData.profilePicture[0]);
  //     imageData.append("upload_preset", "wam_uploads");
  //
  //     const cloudinaryResponse = await axios.post(
  //       `https://api.cloudinary.com/v1_1/reactdiv/image/upload/`,
  //       imageData
  //     );
  //     if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
  //       logger.debug('cloudinaryResponse', cloudinaryResponse); // eslint-disable-line no-console
  //     }
  //     const updateResponse = await axios.put(process.env.NEXT_PUBLIC_WAM_API_URL + `whereAdultsMeet/user/change/${newUserID}`, {
  //       profile: {
  //         profileImageSrc: cloudinaryResponse?.data.secure_url,
  //       }
  //     });
  //     if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
  //       logger.debug('updateResponse', updateResponse); // eslint-disable-line no-console
  //     }
  //
  //     await login({
  //       username: formData.username,
  //       password: formData.password
  //     })
  //
  //     // const accessToken = data?.access_token;
  //     // const clientID = data?.client_id;
  //
  //     // await setUserLoggedIn(true);
  //     // await setCookie('access_token', JSON.stringify(accessToken), {
  //     //   path: '/',
  //     //   maxAge: 3600, // Expires after 1hr
  //     //   sameSite: true,
  //     // });
  //
  //     // Return to home page when logged in and cookied.
  //     // if (cookie.wam_access_token) {
  //     //   await Router.push('/');
  //     // } else {
  //     //   // const router: NextRouter = useRouter();
  //     // }
  //   } catch (err) {
  //     console.log(err);
  //     await setFormLoading(!formLoading)
  //   }
  // };

  // const sendDirectMessage = async (
  //   formData,
  //   data
  // ) => {
  //   if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
  //     // logger.debug('\n' +
  //     //   'updateUserAboutMe(formData, userId)' + '\n' +
  //     //   'loggedInUser:' + loggedInUser + '\n' +
  //     //   'messagedUser:' + data.messagedUser + '\n' +
  //     //   'formData:' + formData
  //     // ); // eslint-disable-line no-console
  //   }
  //   console.log('wstest2 sendDirectMessage websocket', websocket)
  //   // if (websocket !== undefined) {
  //   //   console.log('wstest websocket was not undefined and should send')
  //   chatNotification(data.loggedInUser, data.messagedUser)
  //   try {
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${cookie.wam_access_token}`
  //       }
  //     };
  //     const preparedData = {
  //       id: data.messagedUser,
  //       state: "published",
  //       message: formData.message
  //     };
  //     const response = await axios.put(
  //       `${process.env.NEXT_PUBLIC_WAM_API_URL}whereAdultsMeet/user/messages/send/${data.loggedInUser}`,
  //       preparedData,
  //       config,
  //     );
  //     if (response.status === 200) {  // response - object, eg { status: 200, message: 'OK' }
  //       const {data} = response;
  //       if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
  //         logger.debug('successfully updateUserBaseInfo(userID): userId, data', data.loggedInUser, data); // eslint-disable-line no-console
  //       }
  //       await readDirectMessages(data.loggedInUser, data.messagedUser)
  //     }
  //     return false;
  //   } catch (err) {
  //     if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
  //       logger.error('error:', err); // eslint-disable-line no-console
  //     }
  //     return false;
  //   }
  // }; // NOTE v1.0
  // const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);

  // NOTE User Dialogs
  const [dialogState, setDialogState] = useState({
    baseInfo: false,
    aboutMe: false,
    lifestyle: false,
    characteristics: false,
    gallery: false,
  });
  const handleUserDialogToggles = (dialogName,
    currentDialogState,
    userID) => {
    setDialogState({
      ...currentDialogState,
      [dialogName]: !currentDialogState[dialogName]
    })
  }

  const baseConfig = {
    cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
    apiKey: process.env.NEXT_PUBLIC_CLOUDINARY_API_KEY,
    timestamp: Math.round(new Date().getTime() / 1000),
  };
  const endPoints = {
    sigURI: 'http://localhost:3135/api/cloudinary-signature',
    uploadEndpoint: `https://api.cloudinary.com/v1_1/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload`,
  };
  // NOTE New Code
  const getSignature = async () => {
    try {
      const res = await axios.get(`${endPoints.sigURI}`,
        {
          params: {
            timestamp: baseConfig.timestamp,
          },
          withCredentials: true,
        });
      return res.data.signature;
    } catch (err) {
      if (err.response) {
        return err.response;
      }
      return err;
    }
  };



  const validateUsername = async (username) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('validateUsername', username); // eslint-disable-line no-console
    }
    try {
      const response = await axios.get(process.env.NEXT_PUBLIC_WAM_API_URL + `whereAdultsMeet/validate/user/username/${username}`);
      console.log('validateUsername response', response)
      // const result = response?.data.data;
      return response?.data.data;
    } catch (err) {
      console.log(err);
    }
  }

  const validateEmailAddress = async (emailAddress) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('validateEmailAddress', emailAddress); // eslint-disable-line no-console
    }
    try {
      const response = await axios.get(process.env.NEXT_PUBLIC_WAM_API_URL + `whereAdultsMeet/validate/user/email_address/${emailAddress}`);
      console.log('validateEmailAddress response', response)
      // const result = response?.data.data;
      return response?.data.data;
    } catch (err) {
      console.log(err);
    }
  }

  const register = async (formData,
    event,
    formLoading,
    setFormLoading) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.debug('register formData', formData); // eslint-disable-line no-console
      logger.debug('register formData.profilePicture[0]', formData.profilePicture[0]); // eslint-disable-line no-console
    }
    event.preventDefault()
    await setFormLoading(!formLoading)

    let latitude;
    let longitude;

    // await handleEmailsUserRegistration(formData)

    await getGeocode({address: formData.city}).then((results) => {
      const {lat, lng} = getLatLng(results[0]);
      latitude = lat;
      longitude = lng;
      console.log("📍 Coordinates: ", {lat, lng});
    });

    try {
      const response = await axios.post(process.env.NEXT_PUBLIC_WAM_API_URL + 'whereAdultsMeet/user/create', {
        credentials: {
          username: formData.username,
          type: "user",
          status: "unverified", // This needs a photo ID
          email: formData.emailAddress,
          password: formData.password,
        },
        profile: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          dateOfBirth: formData.dateOfBirth,
          genderIdentity: formData.genderIdentity,
          sexualOrientation: formData.sexualOrientation,
          relationshipStatus: formData.relationshipStatus,
          city: formData.city,
          latitude: latitude,
          longitude: longitude
        },
        about: {
          tagline: formData.tagline,
          biography: formData.shortDes
        }
      });
      const newUserID = response?.data;

      // const signature = await getSignature();
      // const cloudinaryResponse = await axios.post(endPoints.uploadEndpoint, {
      //   data: {
      //     // apiKey: baseConfig.apiKey,
      //     // timestamp: baseConfig.timestamp,
      //     // signature: signature,
      //     file: formData.profilePicture[0],
      //     upload_preset: 'wam_uploads',
      //     // eager: "w_400,h_300,c_pad|w_260,h_200,c_crop",
      //   },
      // });

      // NOTE Upload Image to Cloudinary
      let imageData = new FormData();
      imageData.append("file", formData.profilePicture[0]);
      imageData.append("upload_preset", "wam_uploads");

      const cloudinaryResponse = await axios.post(
        `https://api.cloudinary.com/v1_1/reactdiv/image/upload/`,
        imageData
      );
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('cloudinaryResponse', cloudinaryResponse); // eslint-disable-line no-console
      }
      const updateResponse = await axios.put(process.env.NEXT_PUBLIC_WAM_API_URL + `whereAdultsMeet/user/change/${newUserID}`, {
        profile: {
          profileImageSrc: cloudinaryResponse?.data.secure_url,
        }
      });
      if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
        logger.debug('updateResponse', updateResponse); // eslint-disable-line no-console
      }

      await login({
        username: formData.username,
        password: formData.password
      })

      // const accessToken = data?.access_token;
      // const clientID = data?.client_id;

      // await setUserLoggedIn(true);
      // await setCookie('access_token', JSON.stringify(accessToken), {
      //   path: '/',
      //   maxAge: 3600, // Expires after 1hr
      //   sameSite: true,
      // });

      // Return to home page when logged in and cookied.
      // if (cookie.wam_access_token) {
      //   await Router.push('/');
      // } else {
      //   // const router: NextRouter = useRouter();
      // }
    } catch (err) {
      console.log(err);
      await setFormLoading(!formLoading)
    }
  };
  const login = async (formData,
    event = undefined) => {
    if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
      logger.info('login formData, event', formData.username, formData.password); // eslint-disable-line no-console
    }
    try {
      const response = await axios.post(process.env.NEXT_PUBLIC_WAM_API_URL + 'whereAdultsMeet/user/login', {
        username: formData.username,
        password: formData.password,
      });
      const userData = response?.data.user;
      const accessToken = userData?.access_token;
      const logged_in_user_id = userData?._id;
      console.log('data after', userData)

      await setCookie('wam_logged_in_user', JSON.stringify(userData), {
        path: '/',
        maxAge: 3600,
        sameSite: true
      })
      await setCookie('wam_user_id', JSON.stringify(logged_in_user_id), {
        path: '/',
        maxAge: 3600, // Expires after 10hr
        sameSite: true,
      });
      await setCookie('wam_access_token', JSON.stringify(accessToken), {
        path: '/',
        maxAge: 3600, // Expires after 1hr
        sameSite: true,
      });
      await Router.push(`/user/${logged_in_user_id}`);

      // Return to home page when logged in and cookied.
      // if (cookie.wam_access_token) {
      //   await Router.push(`/user/${clientID}`);
      // } else {
      //   // const router: NextRouter = useRouter();
      // }

    } catch (err) {
      console.log(err);
    }
  };
  const logout = () => {
    console.log('logging out')
    // setUserLoggedIn(false);
    removeCookie('refreshToken', {
      path: '/',
      sameSite: true,
    });
    removeCookie('wam_user_id', {
      path: '/',
      sameSite: true,
    });
    removeCookie('wam_access_token', {
      path: '/',
      sameSite: true,
    });
  };

  const value = {
    // updateProfile,
    // userLoggedIn,
    register,
    login,
    logout,
    validateUsername,
    validateEmailAddress
  };

  return (
    <>
      <UserSessionContext.Provider
        value={value}
      >
        {children}
      </UserSessionContext.Provider>
    </>
  );

}
