import {
  Profile as WamProfile,
  User as WamUser,
} from '@/modules/core/wamAPI/types/schema'
import {
  Profile
} from '@/modules/core/wamAPI/types/profile'
import {
  User,
} from '@/modules/core/wamAPI/types/user';
import {createLogger} from '@/modules/core/logging/logger';

const fileLabel = 'modules/core/wamApi/utils/normalise.ts';
const logger = createLogger({
  fileLabel,
});

export function normaliseProfile(profileData: WamProfile): Profile {
  console.log('normalising Profile', profileData);
  const {
    user_id,
    profile_wizard,
    first_name,
    last_name,
    dob,
    gender_identity,
    relationship_status,
    sexuality,
    city,
    latitude,
    longitude,
    primary_image,
    images,
    likes,
    interested
  } = profileData;

  const profile = {
    user_id: user_id,
    profile_wizard: profile_wizard,
    first_name: first_name,
    last_name: last_name,
    dob: dob,
    gender_identity: gender_identity,
    relationship_status: relationship_status,
    sexuality: sexuality,
    city: city,
    latitude: latitude,
    longitude: longitude,
    primary_image: primary_image,
    images: images,
    likes: likes,
    interested: interested
  }
  return profile
}

export function normaliseUser(userData: WamUser): User {
  console.log('normalising User', userData);
  const {
    _id: id,
    credentials,
    profile,
    about,
    ...rest
  } = userData;

  const user = {
    id,
    type: credentials.type,
    status: credentials.status,
    firstName: profile.firstName,
    lastName: profile.lastName,
    dateOfBirth: profile.dateOfBirth,
    genderIdentity: profile.genderIdentity,
    sexualOrientation: profile.sexualOrientation,
    relationshipStatus: profile.relationshipStatus,
    city: profile.city,
    latitude: profile.latitude,
    longitude: profile.longitude,
    profileImageSrc: profile.profileImageSrc,
    tagline: about.tagline,
    biography: about.biography
  };
  return user;
}

// const normaliseUserCredentials = (credentials) => {
//   console.log('normalising user credentials', credentials);
//   const {
//
//   } = credentials;
//
// };
